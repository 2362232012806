// 引入路由vueRouter
import Vue from 'vue';
import VueRouter from 'vue-router';
// 应用路由VueRouter
Vue.use(VueRouter)


//  isLogin:表示是否需要登录才能访问路由 false否  true是


const routes = [{
		path: '/',
		name: 'home',
		component: () => import('../page/home/index.vue'),
		meta: {
			title: '首页',
			isLogin: false,

		}
	},
	{
		path: '*',
		name: '404',
		component: () => import('../page/404.vue'),
		meta: {
			title: '404',
			isLogin: false,
	
		}
	},
	{
		path: '/chaoshi',
		name: 'chaoshi',
		component: () => import('../page/chaoshi.vue'),
		meta: {
			title: '错误',
			isLogin: false,
	
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../page/login/login.vue'),
		meta: {
			title: '登录',
			isLogin: false,

		}
	},
	{
		path: '/forget',
		name: 'forget',
		component: () => import('../page/forget/index.vue'),
		meta: {
			title: '忘记密码',
			isLogin: false,

		}
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../page/register/index.vue'),
		meta: {
			title: '注册',
			isLogin: false,

		}
	},
	{
		path: '/firmRegister',
		name: 'firmRegister',
		component: () => import('../page/register/firmRegister.vue'),
		meta: {
			title: '企业注册',
			isLogin: false,
	
		}
	},
	{
		path: '/bid',
		name: 'bid',
		component: () => import('../page/bid/bid.vue'),
		
		meta: {
			title: '标的列表页',
			isLogin: false,

		}
	},
	{
		path: '/professionDetails',
		name: 'professionDetails',
		component: () => import('../page/bidDetail/index.vue'),
		meta: {
			title: '标的详情页面',
			isLogin: false,

		}
	},
	{
		path: '/auction',
		name: 'auction',
		component: () => import('../page/auction/auction.vue'),
		meta: {
			title: '拍卖会列表页',
			isLogin: false,

		}
	},
	{
		path: '/targetmulu',
		name: 'targetmulu',
		component: () => import('../page/targetmulu/index.vue'),
		meta: {
			title: '拍卖会详情页',
			isLogin: false,

		}
	},
	{
		path: '/special',
		name: 'special',
		component: () => import('../page/special/home1.vue'),
		meta: {
			title: '企业专场',
			isLogin: false,

		}
	},
	{
		path: '/hebtig',
		name: 'hebtig',
		component: () => import('../page/hebtig/index.vue'),
		meta: {
			title: '河北交投列表页',
			isLogin: false,
	
		}
	},
	{
		path: '/notice',
		name: 'notice',
		component: () => import('../page/notice/index.vue'),
		meta: {
			title: '竞价公告',
			isLogin: false,

		}
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../page/about/index.vue'),
		meta: {
			title: '公司简介',
			isLogin: false,
	
		}
	},
	{
		path: '/formBid',
		name: 'formBid',
		component: () => import('../page/formBid/formBid.vue'),
		meta: {
			title: '报名标的',
			isLogin: true,
	
		}
	},
	{
	    path: '/user',//我的云拍
	    name: 'user',
	    redirect: '/user/account',
	    component: () => import('../page/userInfo/index.vue'),
	    meta: {
	        title: '我的云拍',
	        isLogin: true,//为true表示页面是否需要登录才能查看
	    },
	    children: [
	        {
	            path: '/user/account',//账户信息
	            name: 'useraccount',
	            component: () => import('../page/userInfo/common/account.vue'),
	            meta: {
	                title: '账户信息',
	                isLogin: true,//为true表示页面是否需要登录才能查看
	            },
	        },
	        {
	            path: '/user/name',//实名认证
	            name: 'username',
	            component: () => import('../page/userInfo/common/name.vue'),
	            meta: {
	                title: '实名认证',
	                isLogin: true,//为true表示页面是否需要登录才能查看
	            },
	        },
	        {
	            path: '/user/person',//个人信息
	            name: 'userperson',
	            component: () => import('../page/userInfo/common/person.vue'),
	            meta: {
	                title: '个人信息',
	                isLogin: true,//为true表示页面是否需要登录才能查看
	            },
	        },
	        {
	            path: '/user/person_info',//个人信息的修改信息
	            name: 'userperson_info',
	            component: () => import('../page/userInfo/common/person_info.vue'),
	            meta: {
	                title: '修改个人信息',
	                isLogin: true,//为true表示页面是否需要登录才能查看
	            },
	        },
	        {
	            path: '/user/unsubscribe',//注销账户
	            name: 'userunsubscribe',
	            component: () => import('../page/userInfo/common/unsubscribe.vue'),
	            meta: {
	                title: '注销账户',
	                isLogin: true,//为true表示页面是否需要登录才能查看
	            },
	        },
			{
			    path: '/user/MyAuction',//我的竞拍
			    name: 'userMyAuction',
			    component: () => import('../page/userInfo/common/MyAuction.vue'),
			    meta: {
			        title: '我的竞拍',
			        isLogin: true,//为true表示页面是否需要登录才能查看
			    },
			},
			{
			    path: '/user/MyAttention',//我的竞拍
			    name: 'userMyAttention',
			    component: () => import('../page/userInfo/common/MyAttention.vue'),
			    meta: {
			        title: '我的关注',
			        isLogin: true,//为true表示页面是否需要登录才能查看
			    },
			}
	    ]
	},
	
	
]
const router = new VueRouter({
	linkActiveClass: 'selected',
	routes,
	// mode: 'hash',
	mode: 'history',
	scrollBehavior(to, from, savedPosition) {
		// 跳转新路由  页面滚动到顶部
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				x: 0,
				y: 0
			};
		}
	}
})


export default router